import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { Home, Mint, Pub, Staking, Verify } from "pages";

const App: React.FC = () => {
  return (
    <>
      <ToastContainer theme="dark" />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mint" element={<Mint />} />
          <Route path="/pub" element={<Pub />} />
          <Route path="/stake" element={<Staking />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/verify/:linkId" element={<Verify />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
