import { useEffect, useState } from "react";

export const useCountdown = (targetDate: string) => {
  const countDownDate = new Date(parseInt(targetDate)).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown: number) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  let result = "";
  if (days > 0) {
    result += `<b>${days}</b> day${days > 1 ? "s" : ""} `;
  }
  if (hours > 0) {
    result += `<b>${hours}</b> hour${hours > 1 ? "s" : ""} `;
  }
  if (minutes > 0) {
    result += `<b>${minutes}</b> minute${minutes > 1 ? "s" : ""} `;
  }
  //   if (seconds > 0) {
  //     result += `<b>${seconds}</b> second${seconds > 1 ? "s" : ""} `;
  //   }

  return [result, Math.round(countDown/1000)];
};
