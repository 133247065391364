// import { AnyAction } from "@reduxjs/toolkit";
// import { ThunkAction } from "@reduxjs/toolkit";
// import { RootState } from "../store";
import { stakingSlice } from "../slices/staking";

export const stakingActions = stakingSlice.actions;

// Use the below example to write api/blockchain calls

// export const fetchTodos = (): ThunkAction<
//   void,
//   RootState,
//   unknown,
//   AnyAction
// > => {
//   return async (dispatch, getState) => {
//     if (getState().todo.all_todos.length === 0) {
//       const response: TodoModel[] = await TodoService.getAllTodos();
//       dispatch(todoActions.setTodos(response));
//     }
//   };
// };
