import React, { useEffect, useState } from "react";

import classes from "./index.module.scss";
import ConnectMoralis from "components/connect-moralis";
import homeBgVideo from 'assets/videos/home-bg.mp4';
import { redeemToken } from "../../blockchain/blockchain";
import {
    validateVerificationLinkById,
    verifyUserByLinkId,
} from '../../blockchain/moralis';
import { useAppSelector } from "../../redux/store";
import ConnectWalletTextImage from "assets/text-images/connect-wallet.png";
import { useMoralis } from "react-moralis";
import { useParams } from 'react-router-dom';

enum VerifyResultCode {
    UNVERIFIED = 'UNVERIFIED',
    EXPIRED = 'EXPIRED',
    ACCESS_GRANTED = 'ACCESS_GRANTED',
    NO_TOKENS = 'NO_TOKENS',
}

export const Verify: React.FC = () => {
  const { linkId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [verifiedStatus, setVerifiedStatus] = useState(
      VerifyResultCode.UNVERIFIED
  );
  const [isValidLink, setIsValidLink] = useState(false);
  const [signingMessage, setSigningMessage] = useState('Loading...');

  const walletAddress = useAppSelector(
    (state) => state.walletReducer.selectedWallet
  );

  const isCorrectNetwork = useAppSelector(
      (state) => state.walletReducer.isCorrectNetwork
  );

  useEffect(() => {
    if (!isLoading) return;
    const runAsync = async () => {
        const validatedResult = await validateVerificationLinkById(
            linkId || ''
        ); 
        setIsLoading(false);
        setIsValidLink(!!validatedResult);
        if (validatedResult) {
            setSigningMessage(validatedResult.signingMessage);
            console.log(validatedResult);
        }
    }
    runAsync();
  }, [isLoading]);


  const authenticateCallback = async (user: any) => {
        console.log('authenticated', user);
        const verifyResult = await verifyUserByLinkId(
            linkId || '',
            user.attributes.ethAddress
        );
        
        setVerifiedStatus(verifyResult.code);

        if (verifyResult.code === VerifyResultCode.EXPIRED) {
            setIsValidLink(false);
            return;
        }
  };

    let pageContent = isValidLink ? (
        <>
            <section className={classes.content}>
                <div className={classes.left}></div>
                <div className={classes.middle}>
                    <button className={walletAddress ? classes.disabled : ''}>
                        <ConnectMoralis
                            signingMessage={signingMessage}
                            authenticateCallback={authenticateCallback}
                        >
                            <img
                                src={ConnectWalletTextImage}
                                alt='connect wallet'
                            />
                        </ConnectMoralis>
                    </button>
                </div>
                <div className={classes.right}></div>
            </section>

            <section className={classes.footer}>
                {!isCorrectNetwork && walletAddress && (
                    <p>
                        This wallet is connected to an unsupported network.
                        Please switch to <b>Ethereum Mainnet</b> to mint.
                    </p>
                )}
                {walletAddress ? (
                    <p>{walletAddress}</p>
                ) : (
                    <p>Connect your Metamask for</p>
                )}

                <p>
                    DaoDon Access Card verification to unlock TheDaoDon Discord server.
                </p>
            </section>
        </>
    ) : (
        <>
            <section className={classes.content}>
                <div className={classes.left}></div>
                <div className={classes.middle}>
                    {isLoading
                        ? 'Loading...'
                        : 'Invalid or Expired Verification Link'}
                </div>
                <div className={classes.right}></div>
            </section>
            {!isLoading && (
                <section className={classes.subcontent}>
                    <p>Please use a new verification link through Discord.</p>
                </section>
            )}
        </>
    );

    switch (verifiedStatus) {
        case VerifyResultCode.ACCESS_GRANTED:
            pageContent = (
                <>
                    <section className={classes.content}>
                        <div className={classes.left}></div>
                        <div className={classes.middle}>
                            Your DaoDon Access Card has been verified!
                        </div>
                        <div className={classes.right}></div>
                    </section>
                    <section className={classes.subcontent}>
                        <p>
                            It may take up to 5 minutes for Discord to sync
                            and unlock access TheDaoDon discord server. 
                        </p>
                    </section>
                </>
            );
            break;
        case VerifyResultCode.NO_TOKENS:
            pageContent = (
                <>
                    <section className={classes.content}>
                        <div className={classes.left}></div>
                        <div className={classes.middle}>
                            This wallet doesn't appear to be holding or staking
                            any DaoDon Access Cards.
                        </div>
                        <div className={classes.right}></div>
                    </section>
                    <section className={classes.subcontent}>
                        <p>{walletAddress}</p>
                        <p>
                            You can refresh this page to try connecting a
                            different wallet.
                        </p>
                    </section>
                </>
            );
            break;
    }

  return (
      <div className={classes.container}>
          <video autoPlay muted loop className={classes['bg-video']}>
              <source src={homeBgVideo} type='video/mp4' />
          </video>

          {pageContent}
      </div>
  );
};
