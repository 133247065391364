import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type StakingStateType = {
  locked: boolean;
  isTokenStaked: boolean;
  stakedTokens: string[];
  allTokens: string[];
  selectedToken: string;
};

const initialState: StakingStateType = {
  locked: true,
  isTokenStaked: false,
  stakedTokens: [],
  allTokens: [],
  selectedToken: "",
};

export const stakingSlice = createSlice({
  name: "staking",
  initialState: initialState,
  reducers: {
    setStakingLocked: (
      state,
      { payload }: PayloadAction<{ locked: boolean }>
    ) => {
      state.locked = payload.locked;
    },
    setTokenStaked: (
      state,
      { payload }: PayloadAction<{ isTokenStaked: boolean }>
    ) => {
      state.isTokenStaked = payload.isTokenStaked;
    },
    setSelectedToken: (
      state,
      { payload }: PayloadAction<{ selectedToken: string }>
    ) => {
      state.selectedToken = payload.selectedToken;
    },
    setAllTokens: (
      state,
      { payload }: PayloadAction<{ allTokens: string[] }>
    ) => {
      state.allTokens = payload.allTokens;
    },
    setStakedTokens: (
      state,
      { payload }: PayloadAction<{ stakedTokens: string[] }>
    ) => {
      state.stakedTokens = payload.stakedTokens;
    },
  },
});

export const stakingReducer = stakingSlice.reducer;
