import React from "react";
import { Link } from "react-router-dom";

import classes from "./index.module.scss";
import stakeBgVideo from "../../assets/videos/stake-bg.mp4";
import EnterTextImage from "../../assets/text-images/welcome-to-pub.png";

export const Pub: React.FC = () => {
  return (
    <div className={classes.container}>
      <video autoPlay muted loop className={classes["bg-video"]}>
        <source src={stakeBgVideo} type="video/mp4" />
      </video>

      <Link to="/stake" className={classes.link}>
        <img src={EnterTextImage} alt="welcome to pub" draggable={false} />
      </Link>
    </div>
  );
};
