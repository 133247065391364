import { toast } from "react-toastify";
import { useAppDispatch } from "../redux/store";
import { walletActions } from "../redux/actions/wallet.actions";

type ConnectWalletPropsType = {
  children: React.ReactNode;
};

const ConnectWallet: React.FC<ConnectWalletPropsType> = (props) => {
  const dispatch = useAppDispatch();

  const correctNetworkCheck = (networkId: string | number) => {
    return networkId == 1 || networkId == 4;
  };

  const connectMetamask = async () => {
    try {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      console.log(accounts);
      toast(`Wallet ${accounts[0]} connected!`);

      let isCorrectNetwork = correctNetworkCheck(
        window.ethereum.networkVersion
      );
      dispatch(walletActions.setIsCorrectNetwork({ isCorrectNetwork }));

      if (!isCorrectNetwork) {
        toast.error(
          'Connected to unsupported network. Please switch to "Ethereum Mainnet."'
        );
      }

      dispatch(walletActions.setWallet({ selectedWallet: accounts[0] }));
      // check network, if wrong show error

      window.ethereum.on("accountsChanged", function (accounts: any) {
        dispatch(walletActions.setWallet({ selectedWallet: accounts[0] }));
      });

      window.ethereum.on("chainChanged", (_chainId: any) => {
        isCorrectNetwork = correctNetworkCheck(_chainId);
        dispatch(walletActions.setIsCorrectNetwork({ isCorrectNetwork }));
        if (isCorrectNetwork) {
          toast.success('Connected!!');
        } else {
          toast.error(
            'Connected to unsupported network. Please switch to "Ethereum Mainnet."'
          );
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div onClick={connectMetamask} {...props}>
      {props.children}
    </div>
  );
};

export default ConnectWallet;
