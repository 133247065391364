import React from 'react'
import { Link } from 'react-router-dom'

import classes from './index.module.scss'
// @ts-ignore
import homeBgVideo from 'assets/videos/home-bg.mp4'
import EnterTextImage from 'assets/text-images/enter-to-mint.png'

export const Home: React.FC = () => {
  return (
    <div className={classes.container}>
      <video autoPlay muted loop className={classes['bg-video']}>
        <source src={homeBgVideo} type="video/mp4" />
      </video>

      <Link to="/mint" className={classes.link}>
        <img src={EnterTextImage} alt="enter to mint" draggable={false} />
      </Link>
    </div>
  )
}
