import classes from "./staking.module.scss";
import StakeActions from "./components/stake-actions";
import { useEffect } from "react";
import { Link } from "react-router-dom";

import StakeCounter from "./components/stake-counter";
import ConnectWallet from "components/connect-wallet";
import { useAppSelector, useAppDispatch } from "../../../redux/store";
import { stakingActions } from "../../../redux/actions/staking.actions";
import DaoDonNFT from "../../../assets/videos/nft.mp4";
import SelectCard from "../../../assets/text-images/Select_your_card.png";
import CardStaked from "../../../assets/images/Card_Staked.png";
import { getAllTokens } from "../../../blockchain/moralis";

export const Staking: React.FC = () => {
  const dispatch = useAppDispatch();
  const locked = useAppSelector((state) => state.stakingReducer.locked);
  const isTokenStaked = useAppSelector(
    (state) => state.stakingReducer.isTokenStaked
  );
  const walletAddress = useAppSelector(
    (state) => state.walletReducer.selectedWallet
  );
  const stakedTokens = useAppSelector(
    (state) => state.stakingReducer.stakedTokens
  );
  const allTokens = useAppSelector((state) => state.stakingReducer.allTokens);
  const selectedToken = useAppSelector(
    (state) => state.stakingReducer.selectedToken
  );

  useEffect(() => {
    if (walletAddress) {
      getAllTokens(walletAddress)
        .then(({ allTokens, stakedTokens }) => {
          dispatch(stakingActions.setAllTokens({ allTokens }));
          dispatch(stakingActions.setStakedTokens({ stakedTokens }));
          dispatch(
            stakingActions.setSelectedToken({
              selectedToken: allTokens?.[0],
            })
          );
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [dispatch, walletAddress]);

  useEffect(() => {
    if (selectedToken && stakedTokens?.length) {
      stakedTokens.includes(selectedToken)
        ? dispatch(stakingActions.setTokenStaked({ isTokenStaked: true }))
        : dispatch(stakingActions.setTokenStaked({ isTokenStaked: false }));
    }
  }, [dispatch, selectedToken, stakedTokens]);

  const onSelectChange = (e: any) => {
    dispatch(
      stakingActions.setSelectedToken({ selectedToken: e.target.value })
    );
  };

  const getAddress = () => {
    return walletAddress
      ? `${walletAddress.slice(0, 5)}...${walletAddress.slice(-5)}`
      : "CONNECT WALLET";
  };

  return (
    <div className={classes.container}>
      <section className={classes.content}>
        <div className={classes.row1}>
          <div className={classes.buttonContaier}>
            <Link to={"/pub"}>
              <div className={classes.buttonConnect}>
                <span>Exit Portal</span>
              </div>
            </Link>
          </div>
          <div className={classes.buttonContaier}>
            <ConnectWallet>
              <div className={classes.buttonConnect}>
                <span>{getAddress()}</span>
              </div>
            </ConnectWallet>
          </div>
        </div>
        <div style={{ display: "flex", "justifyContent": "flex-end" }}>
          <p style={{ color: "white" }}>
            Staking elapsed until release of Don Cases
          </p>
        </div>
        <div className={classes.row2}>
          <StakeCounter locked={locked} selectedToken={selectedToken} />
          <div className={classes.nft}>
            {isTokenStaked && <img src={CardStaked} alt="CardStaked" />}
            <video autoPlay muted loop className={classes["bg-video"]}>
              <source src={DaoDonNFT} type="video/mp4" />
            </video>
          </div>
          <StakeActions
            locked={locked}
            selectedToken={selectedToken}
            walletAddress={walletAddress}
            isTokenStaked={isTokenStaked}
          />
        </div>
        {allTokens.length > 0 && (
          <div className={classes.row3}>
            <img src={SelectCard} alt="select card" />
            <select
              name="selectCards"
              onChange={onSelectChange}
              className={classes.select}
            >
              {allTokens.map((token) => (
                <option value={token} key={token}>
                  {token}
                </option>
              ))}
            </select>
          </div>
        )}
      </section>
    </div>
  );
};
