import { useEffect, useState } from "react";
import classes from "../staking.module.scss";
import PercentageStaked from "../../../../assets/images/Percentage_Animation_V2.gif";
import { getAccuredPower } from "../../../../blockchain/blockchain";
import { useCountdown } from "../../../../hooks/useCountdown";
import { useAppDispatch } from "../../../../redux/store";
import { stakingActions } from "../../../../redux/actions/staking.actions";

type StakeCounterProps = {
  locked: boolean;
  selectedToken: string;
};

const StakeCounter: React.FC<StakeCounterProps> = ({
  locked,
  selectedToken,
}) => {
  const dispatch = useAppDispatch();
  const targetDate = process.env["REACT_APP_STAKING_UNLOCKED_DATE"] || "";
  const [countdown, seconds] = useCountdown(targetDate);
  const [power, setPower] = useState("");

  useEffect(() => {
    if (selectedToken) {
      getAccuredPower(selectedToken).then((power) => setPower(power));
    }
  }, [selectedToken]);

  useEffect(() => {
    if (seconds <= 0 && locked) {
      dispatch(stakingActions.setStakingLocked({ locked: false }));
    }
  }, [dispatch, locked, seconds]);

  if (locked) {
    return (
      <div className={classes.stakeCounter}>
        <div className={classes.line}></div>
        <span className={classes.countdownText}>STAKING COUNTDOWN</span>
        <h1
          className={classes.countdownTimer}
          dangerouslySetInnerHTML={{ __html: countdown as string }}
        ></h1>
        <div className={classes.line}></div>
      </div>
    );
  }
  return (
    <div className={classes.stakePercentage}>
      {power && (
        <>
          <img src={PercentageStaked} alt="PercentageStaked" />
          <div className={classes.stakePercentageText}>
            <h1>{`${power}%`}</h1>
            <span>Charge Level</span>
          </div>
        </>
      )}
    </div>
  );
};

export default StakeCounter;
