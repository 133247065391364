import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type WalletReducerState = {
  selectedWallet: string;
  isCorrectNetwork: boolean;
};

const initialState: WalletReducerState = {
  selectedWallet: "",
  isCorrectNetwork: false,
};

export const walletSlice = createSlice({
  name: "Wallet",
  initialState: initialState,
  reducers: {
    setWallet: (
      state: any,
      { payload }: PayloadAction<{ selectedWallet: string }>
    ) => {
      state.selectedWallet = payload.selectedWallet;
    },
    setIsCorrectNetwork: (
      state: any,
      { payload }: PayloadAction<{ isCorrectNetwork: boolean }>
    ) => {
      state.isCorrectNetwork = payload.isCorrectNetwork;
    }
  },
});

export const walletReducer = walletSlice.reducer;
