import { toast } from "react-toastify";
import { useMoralis } from "react-moralis";
import { useAppDispatch } from "../redux/store";
import { walletActions } from "../redux/actions/wallet.actions";

type ConnectWalletPropsType = {
  children: React.ReactNode;
  signingMessage?: string;
  authenticateCallback?: Function;
};

const ConnectWallet: React.FC<ConnectWalletPropsType> = (props) => {
  const dispatch = useAppDispatch();
  const { Moralis, authenticate } = useMoralis();

  const correctNetworkCheck = (networkId: string | number) => {
    return networkId == 1 || networkId == 4;
  };

  const connectMoralis = async () => {
    try {
      const user = await authenticate({
          signingMessage: props.signingMessage,
          provider: 'metamask',
          chainId: process.env.REACT_APP_MORALIS_NETWORK === 'rinkeby' ? 4 : 1,
      });
      console.log(user);

      if (!user) {
        throw new Error('User not connected');
      }

      let isCorrectNetwork = correctNetworkCheck(Moralis.getChainId() || '');
      dispatch(walletActions.setIsCorrectNetwork({ isCorrectNetwork }));

      if (!isCorrectNetwork) {
          toast.error(
              'Connected to unsupported network. Please switch to "Ethereum Mainnet."'
          );
      } 

      const connectedAddr = user.get('ethAddress');
      toast(`Wallet ${connectedAddr} connected!`);
      dispatch(walletActions.setWallet({ selectedWallet: connectedAddr }));

      props.authenticateCallback && props.authenticateCallback(user);

      window.ethereum.on('accountsChanged', function (accounts: any) {
          dispatch(
              walletActions.setWallet({ selectedWallet: accounts[0] })
          );
      });

      window.ethereum.on('chainChanged', (_chainId: any) => {
          isCorrectNetwork = correctNetworkCheck(_chainId);
          dispatch(walletActions.setIsCorrectNetwork({ isCorrectNetwork }));
          if (isCorrectNetwork) {
              toast.success('Connected!!');
          } else {
              toast.error(
                  'Connected to unsupported network. Please switch to "Ethereum Mainnet."'
              );
          }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
      <div onClick={connectMoralis}>
          {props.children}
      </div>
  );
};

export default ConnectWallet;
