import React, { useEffect, useState } from "react";

import classes from "./index.module.scss";
import ConnectWallet from "components/connect-wallet";
import homeBgVideo from "assets/videos/mint-bg.mp4";
import MinusIcon from "assets/icons/minus-circle.png";
import PlusIcon from "assets/icons/plus-circle.png";
import MintTextImage from "assets/text-images/mint.png";
import { redeemToken } from "../../blockchain/blockchain";
import { getTotalMintedToken } from "../../blockchain/moralis";
import { useAppSelector } from "../../redux/store";
import ConnectWalletTextImage from "assets/text-images/connect-wallet.png";

export const Mint: React.FC = () => {
  const [count, setCount] = useState(1);
  const [mintedTokens, setMintedTokens] = useState(0);
  const walletAddress = useAppSelector(
    (state) => state.walletReducer.selectedWallet
  );

  const isCorrectNetwork = useAppSelector(
      (state) => state.walletReducer.isCorrectNetwork
  );

  const mintPrice = parseFloat(process.env["REACT_APP_MINT_PRICE"] || "0.2");
  const maxMintCount = parseInt(process.env["REACT_APP_MAX_COUNT"] || "10");

  const formatPrice = (price: number) => {
    return `${parseFloat(price.toFixed(3))}`;
  };

  useEffect(() => {
    getTotalMintedToken().then((count) => setMintedTokens(count));
  }, []);

  const mintToken = () => {
    if (walletAddress && count > 0) {
      redeemToken(walletAddress, count, formatPrice(mintPrice * count));
    }
  };
  return (
      <div className={classes.container}>
          <video autoPlay muted loop className={classes['bg-video']}>
              <source src={homeBgVideo} type='video/mp4' />
          </video>

          <section className={classes.content}>
              <div className={classes.left}>
                  <div className={classes.count}>
                      <button
                          onClick={() => setCount(count > 0 ? count - 1 : 0)}
                      >
                          <img src={MinusIcon} alt='minus icon' />
                      </button>
                      <span>count: {count}</span>
                      <button
                          onClick={() => {
                              setCount(
                                  count >= maxMintCount
                                      ? maxMintCount
                                      : count + 1
                              );
                          }}
                      >
                          <img src={PlusIcon} alt='plus icon' />
                      </button>
                  </div>

                  <span className={classes.total}>{`total ${formatPrice(
                      count * mintPrice
                  )} ETH`}</span>
              </div>
              <div className={classes.middle}>
                  <button
                      onClick={mintToken}
                      className={
                          count === 0 || !walletAddress || !isCorrectNetwork
                              ? classes.disabled
                              : ''
                      }
                  >
                      <img src={MintTextImage} alt='mint' />
                  </button>
                  <button className={walletAddress ? classes.disabled : ''}>
                      <ConnectWallet>
                          <img
                              src={ConnectWalletTextImage}
                              alt='connect wallet'
                          />
                      </ConnectWallet>
                  </button>
              </div>
              <div className={classes.right}>
                  <span>{`${mintedTokens} / 7777 minted`}</span>
              </div>
          </section>

          <section className={classes.footer}>
              {!isCorrectNetwork && walletAddress && (
                  <p>
                      This wallet is connected to an unsupported network. Please
                      switch to <b>Ethereum Mainnet</b> to mint.
                  </p>
              )}
              {walletAddress ? (
                  <p>{walletAddress}</p>
              ) : (
                  <p>Connect your Metamask</p>
              )}

              <p>{`Mint your DaoDon Access Card for ${mintPrice} Eth.`}</p>
          </section>
      </div>
  );
};
