import classNames from "classnames";
import classes from "../staking.module.scss";
import Locked from "../../../../assets/images/Locked.png";
import StakeLocked from "../../../../assets/icons/Stake_Icon_locked.png";
import StakeUnLocked from "../../../../assets/icons/Stake_Icon_unlocked.png";

import UnStakeLocked from "../../../../assets/icons/Unstake_Icon_locked.png";
import UnStakeUnLocked from "../../../../assets/icons/Unstake_Icon_unlocke.png";

import PendingClaimLocked from "../../../../assets/icons/Pending_Icon_locked.png";
import PendingClaimUnLocked from "../../../../assets/icons/Pending_Icon_unlocked.png";

import ClaimLocked from "../../../../assets/icons/Claim_Icon_locked.png";
import ClaimUnLocked from "../../../../assets/icons/Claim_Icon_unlocked.png";

import { stake, unstake, convert } from "../../../../blockchain/blockchain";

type StakeActionsProps = {
  locked: boolean;
  selectedToken: string;
  walletAddress: string;
  isTokenStaked: boolean;
};

const StakeActions: React.FC<StakeActionsProps> = ({
  locked,
  selectedToken,
  walletAddress,
  isTokenStaked,
}) => {
  const stakeToken = () => {
    if (selectedToken) {
      stake(selectedToken, walletAddress);
    }
  };
  const unstakeToken = () => {
    if (selectedToken) {
      unstake(selectedToken, walletAddress);
    }
  };

  const convertToken = () => {
    if (selectedToken) {
      convert(selectedToken, walletAddress);
    }
  };
  return (
    <div className={classes.stakeFunctions}>
      <div className={locked ? classes.locked : classes.unlocked}>
        <div className={classes.stakeRow}>
          <div
            className={classNames(
              classes.stakeGroup,
              classes.locked
              // isTokenStaked ? classes.locked : classes.unlocked
            )}
          >
            <img src={locked ? StakeLocked : StakeUnLocked} alt="Stake" />
            <button onClick={stakeToken} className={classes.stakeButton} />
          </div>
          <div className={classes.stakeGroup}>
            <img src={locked ? UnStakeLocked : UnStakeUnLocked} alt="UnStake" />
            <button onClick={unstakeToken} className={classes.unStakeButton} />
          </div>
        </div>
        <div className={classes.stakeRow}>
          {/* <div
            className={classes.stakeGroup}
            style={{ pointerEvents: "none", opacity: 0.3 }}
          >
            <img src={PendingClaimLocked} alt="PendingClaim" />
            <button onClick={() => {}} className={classes.pendingClaimButton} />
          </div> */}
          <div
            className={
              classNames(classes.stakeGroup,
              isTokenStaked ? classes.locked : classes.unlocked)
            }
            style={{ pointerEvents: "none", opacity: 0.3 }}
          >
            <img src={locked ? ClaimLocked : ClaimUnLocked} alt="Claim" />
            <button onClick={convertToken} className={classes.claimButton} />
          </div>
        </div>
      </div>
      {locked && (
        <div className={classes.lockImage}>
          <img src={Locked} alt="Locked" />
        </div>
      )}
    </div>
  );
};

export default StakeActions;
