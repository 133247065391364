import Moralis from "moralis";
import { components } from "moralis/types/generated/web3Api";
import Airdrop from "../utils/airdrop";

type ChainListType = components["schemas"]["chainList"];

// Start the moralis instance
Moralis.start({
  serverUrl: process.env.REACT_APP_Server_URL,
  appId: process.env.REACT_APP_Application_ID,
});
console.log("connected to moralis");

// authenticate the app
export const login = async () => {
  let user = Moralis.User.current();
  if (!user) {
    try {
      const user = await Moralis.authenticate({
        signingMessage: "Log in using Moralis",
      });
      console.log(user.get("ethAddress"));
    } catch (error) {
      console.log(error);
    }
  }
};

// get the staked tokens for a user
export const getStakedTokens = async (staker: string) => {
  const StakedToken = Moralis.Object.extend("StakedToken");
  const query = new Moralis.Query(StakedToken);
  query.equalTo("staker", staker);
  const results: any = await query.find();
  const stakedTokens: string[] = results.map((res: any) => res.get("tokenId"));
  console.log(stakedTokens);
  return stakedTokens;
};

// get the unstaked tokens for a user
export const getUnstakedTokens = async (address: string) => {
  const options = {
    chain: process.env["REACT_APP_MORALIS_NETWORK"] as ChainListType,
    address,
    token_address: process.env["REACT_APP_ERC1155_ADDRESS"] || "", // ERC1155 Address
  };
  const response = await Moralis.Web3API.account.getNFTsForContract(options);
  let unStakedTokens: string[] = [];
  if (response?.result) {
    unStakedTokens = response.result.map((res) => res.token_id);
  }
  return unStakedTokens;
};

// get all the tokens for a user
export const getAllTokens = async (address: string) => {
  const stakedTokens = await getStakedTokens(address);
  const unStakedTokens = await getUnstakedTokens(address);
  const allTokens = stakedTokens.concat(unStakedTokens);
  return {
    allTokens: Array.from(new Set(allTokens)),
    stakedTokens,
    unStakedTokens,
  };
};

// get the balance of a token for a user
export const getTotalMintedToken = async () => {
  try {
    const Token = Moralis.Object.extend("TokenPurchased");
    const query = new Moralis.Query(Token);
    query.limit(10000);
    query.equalTo("confirmed", true);
    const results = await query.find();
    console.log(results);
    const count = results ? results.length : 0;
    return count + parseInt(process.env.REACT_APP_MINT_COUNT_OFFSET || "0");
  } catch (error) {
    console.error(error);
  }
  return 0;
};

//
export const callCloudFunction = async (funcName: any, params: any) => {
  try {
    return Moralis.Cloud.run(funcName, params);
  } catch (error) {
    console.error();
  }
};

export const validateVerificationLinkById = async (linkId: string) => {
  try {
    return Moralis.Cloud.run("validateVerificationLinkById", { linkId });
  } catch (error) {
    console.error();
  }
};

export const verifyUserByLinkId = async (
  linkId: string,
  walletAddress: string
) => {
  try {
    return Moralis.Cloud.run("verifyUserByLinkId", {
      linkId,
      chain: process.env.REACT_APP_MORALIS_NETWORK,
      wallet_address: walletAddress,
      token_address: process.env.REACT_APP_ERC1155_ADDRESS,
    });
  } catch (error) {
    console.error();
  }
};

// get airdrop drop
export const getAirdropData = async (
  airdrop: string,
  address: string,
  quantity: number
) => {
  console.log(airdrop, address, quantity);
  // get the merkle proofs for the airdrop
  const MerkleProof = Moralis.Object.extend("MerkleProofs");
  const query = new Moralis.Query(MerkleProof);
  query.equalTo("airdropId", airdrop);
  query.limit(10000);
  const data: any = (await query.find()).map((e) => e.attributes).reverse();
  console.log(data);
  if (data) {
    // create the airdrop object, get the data we need
    const airdropObj = new Airdrop(data);
    const index = airdropObj.getIndex(address);
    const value = airdropObj.getAmount(index);
    const proof = airdropObj.getMerkleProof(index);
    const root = airdropObj.rootHash;
    const leafHash = airdropObj.leaves[index];
    const airdropId = airdrop;
    const key = address;
    return {
      airdropId,
      key,
      quantity,
      value,
      leafHash,
      proof,
      root,
    };
  } else {
    return {};
  }
};
